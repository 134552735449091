/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
// Generated using typescript-generator version 2.24.612 on 2024-11-12 11:21:26.

import {  AuthenticationRequest, ChangePasswordDTO, CreateAdministratorDTO, CreateUserDTO, GetTemplatesDTO, TranslationProduct, UpdateAdministratorDTO, UpdateUserDTO, UserAuthenticationRequest, XTMConnectionParametersDTO, XTMConnectRequest, FilteringParamsDTO } from "./restApplicationClientTypeOverrides";

export interface AbstractCreateOrUpdateUserDTO extends Serializable {
    firstName: string;
    lastName: string;
    interfaceLanguage: string;
    email: string;
    clientUUID: string;
    status: boolean;
    daysToDefaultDueDate: number;
    assignedProjectIds: string[];
    xtmCustomers: XTMCustomerDTO[];
    xtmProjectNamePrefix: string;
    daysAfterRemoveArchivedOrDeletedFiles: number;
    daysAfterRemoveCancelledJobs: number;
    notifyWhenTranslationImported: boolean;
    allowDueDateChange: boolean;
    notifyWhenXTMWorkflowChange: boolean;
}

export interface XTMCustomerDTO extends Serializable {
    xtmCustomerId: number;
    xtmTemplates: XTMCustomerTemplateDTO[];
    orderNumber: number;
}

export interface Serializable {
}

export interface AdministratorDTO extends BaseDTO, Serializable {
    email: string;
    status: boolean;
    clientName: string;
    client: ClientDTO;
}

export interface AuthenticationResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface RefreshTokenResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface CreateClientDTO extends Serializable {
    clientName: string;
    status: boolean;
}

export interface UpdateClientDTO extends Serializable {
    clientName: string;
    status: boolean;
}

export interface ClientDTO extends BaseDTO, Serializable {
    clientName: string;
    status: boolean;
}

export interface KontentConnectRequest extends Serializable {
    code: string;
}

export interface KontentProductDTO extends Serializable {
    id: string;
    name: string;
    codeName: string;
    typeName: string;
    typeCodeName: string;
    workflowStep: string;
    dueDate: Date;
    publishedDate: Date;
    lastModification: Date;
}

export interface XTMConnectDTO extends Serializable {
    xtmAuthorizationId: string;
}

export interface ActiveProjectSearchDTO extends Serializable {
    sourceLanguage: string;
    targetLanguages: string[];
    customerId: number;
}

export interface GetCustomersDTO extends Serializable {
    defaultCustomerId: number;
    customers: XTMCustomerInfoDTO[];
}

export interface XTMProjectVerifyDTO extends Serializable {
    customerId: number;
    templateId: number;
    sourceLanguage: string;
    targetLanguages: string[];
    entries: TranslationProduct[];
}

export interface XTMProjectEntryVerifyDTO extends Serializable {
    entries: XTMProjectEntriesVerifyDTO[];
    haveProjects: boolean;
}

export interface MassOperationRequest extends Serializable {
    jobsIds: string[];
    operation: JobOperation;
}

export interface JobsErrorCodeIgnoreDTO extends Serializable {
    jobUUIDs: string[];
    errorMessageCode: string;
}

export interface ChangedKontentRequest {
    requestId: string;
    operation: Operation;
    productIds?: string[];
    kontentLanguagesCodes?: string[];
}

export interface Resource extends InputStreamSource {
    open: boolean;
    file: any;
    readable: boolean;
    url: URL;
    uri: URI;
    filename: string;
    description: string;
}

export interface ResetPasswordDTO extends Serializable {
    email: string;
    language: string;
}

export interface VerifyTokenDTO extends Serializable {
    token: string;
}

export interface ApiKeysDTO extends Serializable {
    kontentProjectId: string;
    managementApiKey: string;
    deliveryApiKey: string;
}

export interface CreateOrUpdateProjectDTO extends Serializable {
    projectName: string;
    clientUUID: string;
    kontentProjectId: string;
    status: boolean;
    contentTypeIds: string[];
    contentElementTypes: ContentElementType[];
    handledWorkflowStepIds: string[];
    translatedContentWorkflowStepId: string;
    richTextContext: boolean;
    propagateRepeatTranslations: boolean;
    webhookIds: string[];
    usersForWebhooks: AssignedWebhookUserDTO[];
    assignedUsersUUID: string[];
    languageMapping: KontentProjectLanguageMappingDTO[];
}

export interface KontentProjectDTO extends BaseDTO {
    clientUUID: string;
    name: string;
    status: boolean;
    kontentProjectId: string;
    contentTypeIds: string[];
    contentElementTypes: ContentElementType[];
    handledWorkflowIds: string[];
    translatedContentWorkflowStepId: string;
    webhookIds: string[];
    usersForWebhooks: AssignedWebhookUserDTO[];
    assignedUserIds: string[];
    languageMapping: KontentProjectLanguageMappingDTO[];
    editableKontentProjectId: boolean;
    richTextContext: boolean;
    propagateRepeatTranslations: boolean;
}

export interface KontentProjectOverviewDTO extends BaseDTO {
    status: boolean;
    clientName: string;
    kenticoProjectName: string;
}

export interface KontentItemsSearchDTO extends Serializable {
    name?: string;
    codeName?: string;
    languageCodeName?: string;
    types: string[];
    workflows: string[];
    taxonomies: KontentItemsTaxonomySearch[];
}

export interface KontentContentTypeDTO extends Serializable {
    id: string;
    codeName: string;
    lastModified: Date;
    name: string;
    elements: KontentContentElementDTO[];
    externalId: string;
    contentGroups: ContentGroupDTO[];
}

export interface KontentProject {
    id: string;
    name: string;
    environment: string;
}

export interface KontentSnippetDTO extends Serializable {
    id: string;
    codeName: string;
    lastModified: string;
    name: string;
    elements: KontentContentElementDTO[];
    externalId: string;
}

export interface KontentTaxonomyDTO extends Serializable {
    codeName: string;
    extrenalId: string;
    id: string;
    lastModified: string;
    name: string;
    terms: TaxonomyTerm[];
}

export interface TranslateRequestDTO extends Serializable {
    customerId?: number;
    templateId?: number;
    projectName: string;
    description: string;
    products: { [index: string]: TranslationProduct[] };
    sourceLanguage: string;
    targetLanguages: string[];
    dueDate?: Date;
    daysToDefaultDueDate?: number;
    serverUrl: string;
    workflowStepCodeName?: string;
    updateXtmProject: boolean;
    kontentProjectUUID: string;
    userId?: string;
    productIdVersionTimestampNotForUpdateMap?: { [index: string]: number[] };
}

export interface UserDueDateChangeDTO extends Serializable {
    allowDueDateChange: boolean;
    daysToDefaultDueDate: number;
}

export interface UserProjectPrefixDTO extends Serializable {
    prefix: string;
}

export interface UserDTO extends BaseDTO {
    email: string;
    status: boolean;
    clientName: string;
    interfaceLanguage: string;
    client: ClientDTO;
    xtmProperties: UserXTMPropertiesDTO;
    userSpecification: UserSpecificationDTO;
    assignedProjectIds: string[];
}

export interface XTMCustomerTemplateDTO extends Serializable {
    xtmTemplateId: number;
    orderNumber: number;
}

export interface BaseDTO extends Serializable {
    id: string;
    createdAt: Date;
    modifiedAt: Date;
}

export interface Page<T> extends Slice<T> {
    totalPages: number;
    totalElements: number;
}

export interface ItemLanguageVariationsDTO extends Serializable {
    itemId: string;
    sourceLanguage: string;
}

export interface ScheduledTimeDTO extends Serializable {
    scheduledTo: Date;
}

export interface XTMProjectDTO extends Serializable {
    id: number;
    name: string;
    status: string;
    activityEnum: ActivityEnum;
}

export interface XTMCustomerInfoDTO extends Serializable {
    xtmCustomerId: number;
    name: string;
}

export interface XTMProjectEntriesVerifyDTO extends Serializable {
    entryId: string;
    entryName: string;
    projectId: number;
}

export interface XTMLanguageDTO extends Serializable {
    xtmLanguageCode: string;
    xtmLanguageName: string;
}

export interface XTMTemplateDTO extends Serializable {
    xtmTemplateId: number;
    name: string;
}

export interface XTMWorkflowStepDTO extends Serializable {
    step: string;
}

export interface KontentContentDTO extends BaseDTO {
    frontendId: string;
    content: string;
    jobs: KontentContentJob[];
}

export interface ProjectDTO extends Serializable {
    id: string;
    xtmId: number;
    name: string;
    customer: string;
    createdAt: Date;
    dueDate: Date;
    progress: number;
    status: CompletionStatusEnum;
    sourceLanguage: string;
    sourceLanguageName: string;
    targetLanguages: ProjectTargetLanguageDTO[];
    inAnalysis: boolean;
    joinedFilesInXTM: boolean;
}

export interface URL extends Serializable {
}

export interface URI extends Comparable<URI>, Serializable {
}

export interface InputStreamSource {
    inputStream: any;
}

export interface ChangedProductsDTO extends Serializable {
    productId: string;
    name: string;
}

export interface AssignedWebhookUserDTO extends Serializable {
    userUUID: string;
    orderNumber: number;
}

export interface KontentProjectLanguageMappingDTO extends Serializable {
    xtmLanguageCode: string;
    xtmLanguageName: string;
    kontentLanguageCode: string;
    kontentLanguageName: string;
}

export interface KontentItemsTaxonomySearch extends Serializable {
    taxonomyGroupKeys: string[];
    taxonomyTerms: string[];
}

export interface KontentLanguageDTO extends Serializable {
    id: string;
    name: string;
    codeName: string;
    externalId: string;
    isActive: boolean;
    isDefault: boolean;
}

export interface KontentDeliveryTaxonomyDTO extends Serializable {
    name: string;
    codeName: string;
    terms: KontentDeliveryTaxonomyTerm[];
    taxonomyGroupKeys: string[];
}

export interface KontentContentElementTypeDTO extends Serializable {
    name: string;
    codeName: string;
}

export interface KontentContentElementDTO extends Serializable {
    name: string;
    type: ContentElementType;
    id: string;
    codeName: string;
    externalId: string;
    guideLines: string;
    assetCountLimit: ValueCondition;
    maximumFileSize: number;
    allowedFileTypes: AllowedFileType;
    imageWidthLimit: ValueCondition;
    imageHeightLimit: ValueCondition;
    contentGroup: IdObject;
    sourceUrl: string;
    jsonParameters: string;
    allowedElements: IdObject[];
    itemCountLimit: ValueCondition;
    allowedContentTypes: IdObject[];
    mode: ContentElementMode;
    options: ContentElementOption[];
    maximumTextLength: ContentElementElementValueAppliedTo;
    maximumImageSize: number;
    allowedBlocks: AllowedBlock[];
    allowedTextBlocks: AllowedTextBlock[];
    allowedFormatting: AllowedFormatting[];
    allowedTableBlocks: AllowedTableBlock[];
    allowedTableTextBlocks: AllowedTextBlock[];
    allowedTableFormatting: AllowedFormatting[];
    allowedImageTypes: AllowedFileType;
    snippet: IdObject;
    taxonomyGroup: IdObject;
    dependsOn: DependentElement;
    required: boolean;
}

export interface ContentGroupDTO extends Serializable {
    id: string;
    name: string;
    codeName: string;
    externalId: string;
}

export interface TaxonomyTerm {
    id: string;
    name: string;
    terms?: TaxonomyTerm[];
    codename: string;
    external_id: string;
}

export interface KontentWebhookDTO extends Serializable {
    id: string;
    name: string;
    lastModified: string;
}

export interface KontentWorkflowStepDTO extends Serializable {
    id: string;
    name: string;
    codeName: string;
    transitionsTo: string[];
}

export interface SkippedItemDTO extends Serializable {
    id: string;
    productId: string;
    productName: string;
}

export interface UserOverviewDTO extends BaseDTO {
    email: string;
    status: boolean;
    clientName: string;
}

export interface RequesterDTO extends Serializable {
    id: string;
    name: string;
}

export interface UserXTMPropertiesDTO extends BaseDTO {
    xtmCustomers: XTMCustomerDTO[];
    xtmProjectNamePrefix: string;
    daysAfterRemoveArchivedOrDeletedFiles: number;
    daysAfterRemoveCancelledJobs: number;
}

export interface UserSpecificationDTO extends BaseDTO {
    firstName: string;
    lastName: string;
    generateAndUploadScreenshots: boolean;
    automaticImport: boolean;
    importPerJobs: boolean;
    importAllJobs: boolean;
    notifyWhenTranslationImported: boolean;
    allowDueDateChange: boolean;
    importInterval: number;
    daysToDefaultDueDate: number;
    notifyWhenXTMWorkflowChange: boolean;
}

export interface Sort extends Streamable<Order>, Serializable {
    unsorted: boolean;
    sorted: boolean;
}

export interface Pageable {
    offset: number;
    sort: Sort;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    pageNumber: number;
}

export interface KontentContentJob extends Serializable {
    targetLanguage: string;
    status: XTMJobStatus;
}

export interface ProjectTargetLanguageDTO extends Serializable {
    name: string;
    progress: number;
    status: XTMJobStatus;
    language: string;
    files: ProjectDataDTO[];
}

export interface KontentDeliveryTaxonomyTerm extends Serializable {
    name: string;
    codeName: string;
    terms: KontentDeliveryTaxonomyTerm[];
}

export interface ValueCondition {
    value: number;
    condition: AssetCondition;
}

export interface IdObject {
    id: string;
}

export interface ContentElementOption {
    id: string;
    name: string;
    codename: string;
    external_id: string;
}

export interface ContentElementElementValueAppliedTo {
    value: number;
    applies_to: TextAppliedTo;
}

export interface DependentElement {
    element: IdObject;
    snippet: IdObject;
}

export interface Slice<T> extends Streamable<T> {
    size: number;
    content: T[];
    number: number;
    sort: Sort;
    first: boolean;
    last: boolean;
    pageable: Pageable;
    numberOfElements: number;
}

export interface ProjectDataDTO extends Serializable {
    id: string;
    xtmId: number;
    name: string;
    requester: string;
    createdAt: Date;
    dueDate: Date;
    workflowStep: string;
    progress: number;
    status: XTMJobStatus;
    errorMessage: string;
    errorMessagesDetails: string[];
    joinedFile: boolean;
}

export interface Comparable<T> {
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface Order extends Serializable {
    direction: Direction;
    property: string;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    descending: boolean;
    ascending: boolean;
}

export interface Iterable<T> {
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface AutoCloseable {
}

export interface RestApplication<O> {

    /**
     * HTTP GET /
     * Java method: cloud.xtm.kontent.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.kontent.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>>;

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO>;

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult>;

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: UserAuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]>;

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>>;

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: UpdateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO>;

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO>;

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO>;

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]>;

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO>;

    /**
     * HTTP POST /api/contents/check-statuses
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ContentController.checkStatusesForContents
     */
    checkStatusesForContents(frontendIds: string[], options?: O): RestResponse<KontentContentDTO[]>;

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/jobs/action-for-changed-kontent
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.performActionForChangedKontentJobs
     */
    performActionForChangedKontentJobs(request: ChangedKontentRequest, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/jobs/cancel-for-target-language
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.cancelForTargetLanguage
     */
    cancelForTargetLanguage(queryParams: { targetLanguage: string; projectId: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/jobs/ignore-error-code
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.ignoreErrorCode
     */
    ignoreErrorCode(jobsErrorCodeIgnoreDTO: JobsErrorCodeIgnoreDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/jobs/operation-applicable-for
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType$POST$api_jobs_operationapplicablefor(request: MassOperationRequest, options?: O): RestResponse<string[]>;

    /**
     * HTTP GET /api/jobs/{jobId}/operation-applicable-for
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType$GET$api_jobs_jobId_operationapplicablefor(jobId: string, queryParams: { jobOperation: JobOperation; }, options?: O): RestResponse<string[]>;

    /**
     * HTTP PUT /api/jobs/{jobId}/update-due-date
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.updateDueDateForJob
     */
    updateDueDateForJob(jobId: string, queryParams: { newDueDate: Date; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/kontent/connect
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.connect
     */
    connect(connectRequest: KontentConnectRequest, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/kontent/connect/refresh
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.refresh
     */
    refresh(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/connect/status
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.status
     */
    status(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/item
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.findKontentItem
     */
    findKontentItem(queryParams: { projectId: string; itemId: string; }, options?: O): RestResponse<KontentProductDTO>;

    /**
     * HTTP POST /api/kontent/item/languages
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.getItemLanguageVariations
     */
    getItemLanguageVariations(queryParams: { projectId: string; itemId: string[]; }, options?: O): RestResponse<ItemLanguageVariationsDTO[]>;

    /**
     * HTTP POST /api/kontent/item/publish
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.publishKontentItemLanguageVariant
     */
    publishKontentItemLanguageVariant(kontentScheduledTime?: ScheduledTimeDTO, queryParams: { projectId: string; itemId: string; targetLang: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/kontent/item/workflow
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.updateKontentItemLanguageVariantWorkflow
     */
    updateKontentItemLanguageVariantWorkflow(queryParams: { projectId: string; itemId: string; targetLang: string; workflowStepId: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/languages
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getProjectLanguageList
     */
    getProjectLanguageList(project_id: string, options?: O): RestResponse<KontentLanguageDTO[]>;

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/taxonomies
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getTaxonomyListByKontentProject
     */
    getTaxonomyListByKontentProject(project_id: string, options?: O): RestResponse<KontentDeliveryTaxonomyDTO[]>;

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/types
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getContentTypesList
     */
    getContentTypesList(project_id: string, options?: O): RestResponse<KontentContentTypeDTO[]>;

    /**
     * HTTP GET /api/kontent/project/delivery/{kontentProjectId}/languages
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getProjectLanguageListByKontentProject
     */
    getProjectLanguageListByKontentProject(kontentProjectId: string, options?: O): RestResponse<KontentLanguageDTO[]>;

    /**
     * HTTP GET /api/kontent/project/delivery/{kontentProjectId}/types
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getContentTypesListByKontentProject
     */
    getContentTypesListByKontentProject(kontentProjectId: string, options?: O): RestResponse<KontentContentTypeDTO[]>;

    /**
     * HTTP POST /api/kontent/project/delivery/{project_id}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getItems
     */
    getItems(project_id: string, kontentItemsSearchDTO: KontentItemsSearchDTO, queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<KontentProductDTO>>;

    /**
     * HTTP GET /api/kontent/project/management/elementtypes
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getContentElementTypesList
     */
    getContentElementTypesList(options?: O): RestResponse<KontentContentElementTypeDTO[]>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getProjectInfo
     */
    getProjectInfo(project_id: string, options?: O): RestResponse<KontentProject>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/snippets
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getSnippetList
     */
    getSnippetList(project_id: string, options?: O): RestResponse<KontentSnippetDTO[]>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/snippets/{snippet_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getSnippet
     */
    getSnippet(project_id: string, snippet_identifier: string, options?: O): RestResponse<KontentSnippetDTO>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/taxonomies
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getTaxonomies
     */
    getTaxonomies(project_id: string, options?: O): RestResponse<KontentTaxonomyDTO[]>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/taxonomies/{taxonomy_group_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getTaxonomy
     */
    getTaxonomy(project_id: string, taxonomy_group_identifier: string, options?: O): RestResponse<KontentTaxonomyDTO>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/types/{type_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getContentType
     */
    getContentType(project_id: string, type_identifier: string, options?: O): RestResponse<KontentContentTypeDTO>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/webhooks
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getWebhookList
     */
    getWebhookList(project_id: string, options?: O): RestResponse<KontentWebhookDTO[]>;

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/workflow
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getWorkflowStepList
     */
    getWorkflowStepList(project_id: string, options?: O): RestResponse<KontentWorkflowStepDTO[]>;

    /**
     * HTTP GET /api/kontent/project/specification/languages/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectLanguageMapping
     */
    getProjectLanguageMapping(projectId: string, options?: O): RestResponse<KontentProjectLanguageMappingDTO[]>;

    /**
     * HTTP GET /api/kontent/project/specification/types/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectTypes
     */
    getProjectTypes(projectId: string, options?: O): RestResponse<KontentContentTypeDTO[]>;

    /**
     * HTTP GET /api/kontent/project/specification/workflows/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectWorkflows
     */
    getProjectWorkflows(projectId: string, options?: O): RestResponse<KontentWorkflowStepDTO[]>;

    /**
     * HTTP POST /api/kontent/projects
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.createProject
     */
    createProject(createProject: CreateOrUpdateProjectDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/projects
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllProjectsByClientId
     */
    getAllProjectsByClientId(queryParams: { clientUUID: string; }, options?: O): RestResponse<KontentProjectOverviewDTO[]>;

    /**
     * HTTP GET /api/kontent/projects/apikey/{kontentProjectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentApiKeyController.getApiKey
     */
    getApiKey(kontentProjectId: string, options?: O): RestResponse<ApiKeysDTO>;

    /**
     * HTTP POST /api/kontent/projects/connect
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentApiKeyController.connectKontentProject
     */
    connectKontentProject(queryParams: { managementApiKey: string; deliveryApiKey: string; kontentProjectId: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/projects/kontentId/{kontentProjectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getProjectByKontentProjectId
     */
    getProjectByKontentProjectId(kontentProjectId: string, options?: O): RestResponse<KontentProjectOverviewDTO>;

    /**
     * HTTP GET /api/kontent/projects/pageable
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllProjects
     */
    getAllProjects(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<KontentProjectOverviewDTO>>;

    /**
     * HTTP GET /api/kontent/projects/user
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllActiveProjectsByUserId
     */
    getAllActiveProjectsByUserId(options?: O): RestResponse<KontentProjectOverviewDTO[]>;

    /**
     * HTTP DELETE /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.deleteProject
     */
    deleteProject(projectId: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getProject
     */
    getProject(projectId: string, options?: O): RestResponse<KontentProjectDTO>;

    /**
     * HTTP PUT /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.updateProject
     */
    updateProject(projectId: string, updateProject: CreateOrUpdateProjectDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/kontent/webhook/item/translate/workflowstepevent
     * Java method: cloud.xtm.kontent.backend.webhook.app.api.KontentWebhookItemController.translateItemOnWorkflowStepEvent
     */
    translateItemOnWorkflowStepEvent(rawBody: string, queryParams: { source_language: string; target_languages: string[]; xtm_customer_id?: number; xtm_template_id?: number; due_date?: number; project_name?: string; description?: string; update_xtm_project?: boolean; workflow_step?: string; }, options?: O): RestResponse<HttpStatus>;

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/parametrization/admin-panel-connector-url
     * Java method: cloud.xtm.kontent.backend.parametrization.app.api.AdminPanelParametrizationController.getAdminPanelConnectorUrl
     */
    getAdminPanelConnectorUrl(options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/projects/action-for-changed-kontents
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.performActionForChangedKontentProjects
     */
    performActionForChangedKontentProjects(request: ChangedKontentRequest, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/projects/filter
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.filterProjects
     */
    filterProjects(filteringParamsDTO: FilteringParamsDTO, queryParams: { kontentProjectUUID: string; forceUpdate?: boolean; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ProjectDTO>>;

    /**
     * HTTP POST /api/projects/report
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.generateReport
     */
    generateReport(filteringParamsDTO: FilteringParamsDTO, queryParams: { kontentProjectUUID: string; }, options?: O): RestResponse<Resource>;

    /**
     * HTTP PUT /api/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.addTargetLanguages
     */
    addTargetLanguages(projectId: string, queryParams: { newTargetLanguages: string[]; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/projects/{projectId}/get-changed-kontent-products
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.getChangedKontentProducts
     */
    getChangedKontentProducts(projectId: string, queryParams: { sort: boolean; }, options?: O): RestResponse<ChangedProductsDTO[]>;

    /**
     * HTTP PUT /api/projects/{projectId}/remove-target-languages
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.removeTargetLanguagesTargetLanguages
     */
    removeTargetLanguagesTargetLanguages(projectId: string, queryParams: { targetLanguagesToRemove: string[]; }, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/projects/{projectId}/update-due-date
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.updateDueDateForProject
     */
    updateDueDateForProject(projectId: string, queryParams: { newDueDate: Date; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/translations/add-to-queue
     * Java method: cloud.xtm.kontent.backend.translation.app.api.XTMTranslationController.addToQueue
     */
    addToQueue(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/translations/translate
     * Java method: cloud.xtm.kontent.backend.translation.app.api.XTMTranslationController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<SkippedItemDTO[]>;

    /**
     * HTTP POST /api/users
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/users
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllUsersList
     */
    getAllUsersList(queryParams: { clientUUID: string; }, options?: O): RestResponse<UserOverviewDTO[]>;

    /**
     * HTTP GET /api/users/due-date-change
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllowDueDateChangeForCurrentUser
     */
    getAllowDueDateChangeForCurrentUser(options?: O): RestResponse<UserDueDateChangeDTO>;

    /**
     * HTTP GET /api/users/pageable
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>>;

    /**
     * HTTP GET /api/users/project-prefix
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getProjectPrefixForCurrentUser
     */
    getProjectPrefixForCurrentUser(options?: O): RestResponse<UserProjectPrefixDTO>;

    /**
     * HTTP GET /api/users/requester-list
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getRequestersForProject
     */
    getRequestersForProject(queryParams: { kontentProjectUUID: string; }, options?: O): RestResponse<RequesterDTO[]>;

    /**
     * HTTP DELETE /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO>;

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/callbacks/project/analysis/finished/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.analysisFinished
     */
    analysisFinished(clientId: string, queryParams: { xtmCustomerId: number; xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/callbacks/workflow/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.updateWorkflow
     */
    updateWorkflow(clientId: string, queryParams: { additionalData: string; xtmCustomerId: number; xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO>;

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerInfoDTO[]>;

    /**
     * HTTP GET /api/xtm/customers/current-user
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForCurrentUser
     */
    getXTMCustomersForCurrentUser(options?: O): RestResponse<XTMCustomerInfoDTO[]>;

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerInfoDTO[]>;

    /**
     * HTTP GET /api/xtm/languages/languages
     * Java method: cloud.xtm.kontent.backend.integration.xtm.languages.app.api.LanguageController.getLanguages
     */
    getLanguages(options?: O): RestResponse<XTMLanguageDTO[]>;

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.kontent.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerIds: number[]; }, options?: O): RestResponse<{ [index: string]: XTMTemplateDTO[] }>;

    /**
     * HTTP GET /api/xtm/workflows
     * Java method: cloud.xtm.kontent.backend.integration.xtm.workflow.app.api.WorkflowsController.getXTMWorkflowSteps
     */
    getXTMWorkflowSteps(options?: O): RestResponse<XTMWorkflowStepDTO[]>;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class RestApplicationClient<O> implements RestApplication {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /
     * Java method: cloud.xtm.kontent.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding``, options: options });
    }

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.kontent.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api`, options: options });
    }

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/administrators`, data: createAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/administrators/${administratorId}`, data: updateAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.kontent.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/admin`, data: authRequest, options: options });
    }

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/auth/refreshToken`, options: options });
    }

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.kontent.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: UserAuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/user`, data: authRequest, options: options });
    }

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/client`, data: createClient, options: options });
    }

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client`, options: options });
    }

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: UpdateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/client/${clientId}`, data: updateClient, options: options });
    }

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.kontent.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/customers`, options: options });
    }

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/entries/verify`, data: xtmProject, options: options });
    }

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/projects/active`, data: activeProjectSearch, options: options });
    }

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/templates/${xtmCustomerId}`, options: options });
    }

    /**
     * HTTP POST /api/contents/check-statuses
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ContentController.checkStatusesForContents
     */
    checkStatusesForContents(frontendIds: string[], options?: O): RestResponse<KontentContentDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contents/check-statuses`, data: frontendIds, options: options });
    }

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs`, data: request, options: options });
    }

    /**
     * HTTP POST /api/jobs/action-for-changed-kontent
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.performActionForChangedKontentJobs
     */
    performActionForChangedKontentJobs(request: ChangedKontentRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs/action-for-changed-kontent`, data: request, options: options });
    }

    /**
     * HTTP PUT /api/jobs/cancel-for-target-language
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.cancelForTargetLanguage
     */
    cancelForTargetLanguage(queryParams: { targetLanguage: string; projectId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/jobs/cancel-for-target-language`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/jobs/ignore-error-code
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.ignoreErrorCode
     */
    ignoreErrorCode(jobsErrorCodeIgnoreDTO: JobsErrorCodeIgnoreDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/jobs/ignore-error-code`, data: jobsErrorCodeIgnoreDTO, options: options });
    }

    /**
     * HTTP POST /api/jobs/operation-applicable-for
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType$POST$api_jobs_operationapplicablefor(request: MassOperationRequest, options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs/operation-applicable-for`, data: request, options: options });
    }

    /**
     * HTTP GET /api/jobs/{jobId}/operation-applicable-for
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType$GET$api_jobs_jobId_operationapplicablefor(jobId: string, queryParams: { jobOperation: JobOperation; }, options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/jobs/${jobId}/operation-applicable-for`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/jobs/{jobId}/update-due-date
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.JobsController.updateDueDateForJob
     */
    updateDueDateForJob(jobId: string, queryParams: { newDueDate: Date; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/jobs/${jobId}/update-due-date`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/kontent/connect
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.connect
     */
    connect(connectRequest: KontentConnectRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/connect`, data: connectRequest, options: options });
    }

    /**
     * HTTP POST /api/kontent/connect/refresh
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.refresh
     */
    refresh(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/connect/refresh`, options: options });
    }

    /**
     * HTTP GET /api/kontent/connect/status
     * Java method: cloud.xtm.kontent.backend.integration.kontent.auth.app.api.KontentConnectController.status
     */
    status(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/connect/status`, options: options });
    }

    /**
     * HTTP GET /api/kontent/item
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.findKontentItem
     */
    findKontentItem(queryParams: { projectId: string; itemId: string; }, options?: O): RestResponse<KontentProductDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/item`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/kontent/item/languages
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.getItemLanguageVariations
     */
    getItemLanguageVariations(queryParams: { projectId: string; itemId: string[]; }, options?: O): RestResponse<ItemLanguageVariationsDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/item/languages`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/kontent/item/publish
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.publishKontentItemLanguageVariant
     */
    publishKontentItemLanguageVariant(kontentScheduledTime?: ScheduledTimeDTO, queryParams: { projectId: string; itemId: string; targetLang: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/item/publish`, queryParams: queryParams, data: kontentScheduledTime, options: options });
    }

    /**
     * HTTP POST /api/kontent/item/workflow
     * Java method: cloud.xtm.kontent.backend.integration.kontent.item.api.KontentItemController.updateKontentItemLanguageVariantWorkflow
     */
    updateKontentItemLanguageVariantWorkflow(queryParams: { projectId: string; itemId: string; targetLang: string; workflowStepId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/item/workflow`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/languages
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getProjectLanguageList
     */
    getProjectLanguageList(project_id: string, options?: O): RestResponse<KontentLanguageDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/delivery/uuid/${project_id}/languages`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/taxonomies
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getTaxonomyListByKontentProject
     */
    getTaxonomyListByKontentProject(project_id: string, options?: O): RestResponse<KontentDeliveryTaxonomyDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/delivery/uuid/${project_id}/taxonomies`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/delivery/uuid/{project_id}/types
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getContentTypesList
     */
    getContentTypesList(project_id: string, options?: O): RestResponse<KontentContentTypeDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/delivery/uuid/${project_id}/types`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/delivery/{kontentProjectId}/languages
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getProjectLanguageListByKontentProject
     */
    getProjectLanguageListByKontentProject(kontentProjectId: string, options?: O): RestResponse<KontentLanguageDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/delivery/${kontentProjectId}/languages`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/delivery/{kontentProjectId}/types
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getContentTypesListByKontentProject
     */
    getContentTypesListByKontentProject(kontentProjectId: string, options?: O): RestResponse<KontentContentTypeDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/delivery/${kontentProjectId}/types`, options: options });
    }

    /**
     * HTTP POST /api/kontent/project/delivery/{project_id}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectDeliveryController.getItems
     */
    getItems(project_id: string, kontentItemsSearchDTO: KontentItemsSearchDTO, queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<KontentProductDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/project/delivery/${project_id}`, queryParams: queryParams, data: kontentItemsSearchDTO, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/elementtypes
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getContentElementTypesList
     */
    getContentElementTypesList(options?: O): RestResponse<KontentContentElementTypeDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/elementtypes`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getProjectInfo
     */
    getProjectInfo(project_id: string, options?: O): RestResponse<KontentProject> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/snippets
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getSnippetList
     */
    getSnippetList(project_id: string, options?: O): RestResponse<KontentSnippetDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/snippets`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/snippets/{snippet_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getSnippet
     */
    getSnippet(project_id: string, snippet_identifier: string, options?: O): RestResponse<KontentSnippetDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/snippets/${snippet_identifier}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/taxonomies
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getTaxonomies
     */
    getTaxonomies(project_id: string, options?: O): RestResponse<KontentTaxonomyDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/taxonomies`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/taxonomies/{taxonomy_group_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getTaxonomy
     */
    getTaxonomy(project_id: string, taxonomy_group_identifier: string, options?: O): RestResponse<KontentTaxonomyDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/taxonomies/${taxonomy_group_identifier}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/types/{type_identifier}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getContentType
     */
    getContentType(project_id: string, type_identifier: string, options?: O): RestResponse<KontentContentTypeDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/types/${type_identifier}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/webhooks
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getWebhookList
     */
    getWebhookList(project_id: string, options?: O): RestResponse<KontentWebhookDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/webhooks`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/management/{project_id}/workflow
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectManagementController.getWorkflowStepList
     */
    getWorkflowStepList(project_id: string, options?: O): RestResponse<KontentWorkflowStepDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/management/${project_id}/workflow`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/specification/languages/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectLanguageMapping
     */
    getProjectLanguageMapping(projectId: string, options?: O): RestResponse<KontentProjectLanguageMappingDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/specification/languages/${projectId}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/specification/types/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectTypes
     */
    getProjectTypes(projectId: string, options?: O): RestResponse<KontentContentTypeDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/specification/types/${projectId}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/project/specification/workflows/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectSpecificationController.getProjectWorkflows
     */
    getProjectWorkflows(projectId: string, options?: O): RestResponse<KontentWorkflowStepDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/project/specification/workflows/${projectId}`, options: options });
    }

    /**
     * HTTP POST /api/kontent/projects
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.createProject
     */
    createProject(createProject: CreateOrUpdateProjectDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/projects`, data: createProject, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllProjectsByClientId
     */
    getAllProjectsByClientId(queryParams: { clientUUID: string; }, options?: O): RestResponse<KontentProjectOverviewDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects/apikey/{kontentProjectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentApiKeyController.getApiKey
     */
    getApiKey(kontentProjectId: string, options?: O): RestResponse<ApiKeysDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects/apikey/${kontentProjectId}`, options: options });
    }

    /**
     * HTTP POST /api/kontent/projects/connect
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentApiKeyController.connectKontentProject
     */
    connectKontentProject(queryParams: { managementApiKey: string; deliveryApiKey: string; kontentProjectId: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/projects/connect`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects/kontentId/{kontentProjectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getProjectByKontentProjectId
     */
    getProjectByKontentProjectId(kontentProjectId: string, options?: O): RestResponse<KontentProjectOverviewDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects/kontentId/${kontentProjectId}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects/pageable
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllProjects
     */
    getAllProjects(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<KontentProjectOverviewDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects/user
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getAllActiveProjectsByUserId
     */
    getAllActiveProjectsByUserId(options?: O): RestResponse<KontentProjectOverviewDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects/user`, options: options });
    }

    /**
     * HTTP DELETE /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.deleteProject
     */
    deleteProject(projectId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/kontent/projects/${projectId}`, options: options });
    }

    /**
     * HTTP GET /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.getProject
     */
    getProject(projectId: string, options?: O): RestResponse<KontentProjectDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/kontent/projects/${projectId}`, options: options });
    }

    /**
     * HTTP PUT /api/kontent/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.projects.app.api.KontentProjectController.updateProject
     */
    updateProject(projectId: string, updateProject: CreateOrUpdateProjectDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/kontent/projects/${projectId}`, data: updateProject, options: options });
    }

    /**
     * HTTP POST /api/kontent/webhook/item/translate/workflowstepevent
     * Java method: cloud.xtm.kontent.backend.webhook.app.api.KontentWebhookItemController.translateItemOnWorkflowStepEvent
     */
    translateItemOnWorkflowStepEvent(rawBody: string, queryParams: { source_language: string; target_languages: string[]; xtm_customer_id?: number; xtm_template_id?: number; due_date?: number; project_name?: string; description?: string; update_xtm_project?: boolean; workflow_step?: string; }, options?: O): RestResponse<HttpStatus> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/kontent/webhook/item/translate/workflowstepevent`, queryParams: queryParams, data: rawBody, options: options });
    }

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/admin/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/change-password`, data: changePassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.kontent.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/verify-reset-password-token`, data: verifyToken, options: options });
    }

    /**
     * HTTP GET /api/parametrization/admin-panel-connector-url
     * Java method: cloud.xtm.kontent.backend.parametrization.app.api.AdminPanelParametrizationController.getAdminPanelConnectorUrl
     */
    getAdminPanelConnectorUrl(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/parametrization/admin-panel-connector-url`, options: options });
    }

    /**
     * HTTP POST /api/projects/action-for-changed-kontents
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.performActionForChangedKontentProjects
     */
    performActionForChangedKontentProjects(request: ChangedKontentRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/projects/action-for-changed-kontents`, data: request, options: options });
    }

    /**
     * HTTP POST /api/projects/filter
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.filterProjects
     */
    filterProjects(filteringParamsDTO: FilteringParamsDTO, queryParams: { kontentProjectUUID: string; forceUpdate?: boolean; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ProjectDTO>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/projects/filter`, queryParams: queryParams, data: filteringParamsDTO, options: options });
    }

    /**
     * HTTP POST /api/projects/report
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.generateReport
     */
    generateReport(filteringParamsDTO: FilteringParamsDTO, queryParams: { kontentProjectUUID: string; }, options?: O): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/projects/report`, queryParams: queryParams, data: filteringParamsDTO, options: options });
    }

    /**
     * HTTP PUT /api/projects/{projectId}
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.addTargetLanguages
     */
    addTargetLanguages(projectId: string, queryParams: { newTargetLanguages: string[]; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/projects/${projectId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/projects/{projectId}/get-changed-kontent-products
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.getChangedKontentProducts
     */
    getChangedKontentProducts(projectId: string, queryParams: { sort: boolean; }, options?: O): RestResponse<ChangedProductsDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/projects/${projectId}/get-changed-kontent-products`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/projects/{projectId}/remove-target-languages
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.removeTargetLanguagesTargetLanguages
     */
    removeTargetLanguagesTargetLanguages(projectId: string, queryParams: { targetLanguagesToRemove: string[]; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/projects/${projectId}/remove-target-languages`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/projects/{projectId}/update-due-date
     * Java method: cloud.xtm.kontent.backend.jobs.app.api.ProjectsController.updateDueDateForProject
     */
    updateDueDateForProject(projectId: string, queryParams: { newDueDate: Date; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/projects/${projectId}/update-due-date`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/translations/add-to-queue
     * Java method: cloud.xtm.kontent.backend.translation.app.api.XTMTranslationController.addToQueue
     */
    addToQueue(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/translations/add-to-queue`, data: translateRequest, options: options });
    }

    /**
     * HTTP POST /api/translations/translate
     * Java method: cloud.xtm.kontent.backend.translation.app.api.XTMTranslationController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<SkippedItemDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/translations/translate`, data: translateRequest, options: options });
    }

    /**
     * HTTP POST /api/users
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users`, data: createUser, options: options });
    }

    /**
     * HTTP GET /api/users
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllUsersList
     */
    getAllUsersList(queryParams: { clientUUID: string; }, options?: O): RestResponse<UserOverviewDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/users/due-date-change
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllowDueDateChangeForCurrentUser
     */
    getAllowDueDateChangeForCurrentUser(options?: O): RestResponse<UserDueDateChangeDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/due-date-change`, options: options });
    }

    /**
     * HTTP GET /api/users/pageable
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/users/project-prefix
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getProjectPrefixForCurrentUser
     */
    getProjectPrefixForCurrentUser(options?: O): RestResponse<UserProjectPrefixDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/project-prefix`, options: options });
    }

    /**
     * HTTP GET /api/users/requester-list
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getRequestersForProject
     */
    getRequestersForProject(queryParams: { kontentProjectUUID: string; }, options?: O): RestResponse<RequesterDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/requester-list`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/users/${userId}`, options: options });
    }

    /**
     * HTTP PUT /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/users/${userId}`, data: updateUser, options: options });
    }

    /**
     * HTTP GET /api/users/{userId}
     * Java method: cloud.xtm.kontent.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/callbacks/jobs/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/callbacks/project/analysis/finished/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.analysisFinished
     */
    analysisFinished(clientId: string, queryParams: { xtmCustomerId: number; xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/callbacks/project/analysis/finished/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/callbacks/workflow/{clientId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.callback.app.api.XTMCallbackController.updateWorkflow
     */
    updateWorkflow(clientId: string, queryParams: { additionalData: string; xtmCustomerId: number; xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/callbacks/workflow/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/connect/${userId}`, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect/${userId}`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerInfoDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers/current-user
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForCurrentUser
     */
    getXTMCustomersForCurrentUser(options?: O): RestResponse<XTMCustomerInfoDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers/current-user`, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.kontent.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerInfoDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/languages/languages
     * Java method: cloud.xtm.kontent.backend.integration.xtm.languages.app.api.LanguageController.getLanguages
     */
    getLanguages(options?: O): RestResponse<XTMLanguageDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/languages/languages`, options: options });
    }

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.kontent.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerIds: number[]; }, options?: O): RestResponse<{ [index: string]: XTMTemplateDTO[] }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/templates`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/workflows
     * Java method: cloud.xtm.kontent.backend.integration.xtm.workflow.app.api.WorkflowsController.getXTMWorkflowSteps
     */
    getXTMWorkflowSteps(options?: O): RestResponse<XTMWorkflowStepDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/workflows`, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type JobOperation = "CANCEL" | "DELETE" | "IMPORT" | "SEND";

export type HttpStatus = "CONTINUE" | "SWITCHING_PROTOCOLS" | "PROCESSING" | "CHECKPOINT" | "OK" | "CREATED" | "ACCEPTED" | "NON_AUTHORITATIVE_INFORMATION" | "NO_CONTENT" | "RESET_CONTENT" | "PARTIAL_CONTENT" | "MULTI_STATUS" | "ALREADY_REPORTED" | "IM_USED" | "MULTIPLE_CHOICES" | "MOVED_PERMANENTLY" | "FOUND" | "MOVED_TEMPORARILY" | "SEE_OTHER" | "NOT_MODIFIED" | "USE_PROXY" | "TEMPORARY_REDIRECT" | "PERMANENT_REDIRECT" | "BAD_REQUEST" | "UNAUTHORIZED" | "PAYMENT_REQUIRED" | "FORBIDDEN" | "NOT_FOUND" | "METHOD_NOT_ALLOWED" | "NOT_ACCEPTABLE" | "PROXY_AUTHENTICATION_REQUIRED" | "REQUEST_TIMEOUT" | "CONFLICT" | "GONE" | "LENGTH_REQUIRED" | "PRECONDITION_FAILED" | "PAYLOAD_TOO_LARGE" | "REQUEST_ENTITY_TOO_LARGE" | "URI_TOO_LONG" | "REQUEST_URI_TOO_LONG" | "UNSUPPORTED_MEDIA_TYPE" | "REQUESTED_RANGE_NOT_SATISFIABLE" | "EXPECTATION_FAILED" | "I_AM_A_TEAPOT" | "INSUFFICIENT_SPACE_ON_RESOURCE" | "METHOD_FAILURE" | "DESTINATION_LOCKED" | "UNPROCESSABLE_ENTITY" | "LOCKED" | "FAILED_DEPENDENCY" | "TOO_EARLY" | "UPGRADE_REQUIRED" | "PRECONDITION_REQUIRED" | "TOO_MANY_REQUESTS" | "REQUEST_HEADER_FIELDS_TOO_LARGE" | "UNAVAILABLE_FOR_LEGAL_REASONS" | "INTERNAL_SERVER_ERROR" | "NOT_IMPLEMENTED" | "BAD_GATEWAY" | "SERVICE_UNAVAILABLE" | "GATEWAY_TIMEOUT" | "HTTP_VERSION_NOT_SUPPORTED" | "VARIANT_ALSO_NEGOTIATES" | "INSUFFICIENT_STORAGE" | "LOOP_DETECTED" | "BANDWIDTH_LIMIT_EXCEEDED" | "NOT_EXTENDED" | "NETWORK_AUTHENTICATION_REQUIRED";

export type Operation = "CREATE_NEW" | "UPDATE" | "IGNORE";

export type ContentElementType = "text" | "rich_text" | "number" | "multiple_choice" | "date_time" | "asset" | "modular_content" | "custom" | "url_slug" | "guidelines" | "snippet" | "taxonomy" | "subpages";

export type ActivityEnum = "ACTIVE" | "ARCHIVED" | "AUTO_ARCHIVED" | "DELETED" | "INACTIVE";

export type CompletionStatusEnum = "IN_PROGRESS" | "PARTIALLY_FINISHED" | "FINISHED" | "NEW";

export type AllowedFileType = "adjustable" | "any";

export type ContentElementMode = "single" | "multiple";

export type AllowedBlock = "images" | "text" | "tables" | "components-and-items";

export type AllowedTextBlock = "paragraph" | "heading-one" | "heading-two" | "heading-three" | "heading-four" | "ordered-list" | "unordered-list";

export type AllowedFormatting = "unstyled" | "bold" | "italic" | "code" | "link" | "subscript" | "superscript";

export type AllowedTableBlock = "images" | "text";

export type XTMJobStatus = "NEW" | "SENT" | "IN_PROGRESS" | "FINISHED" | "CANCELLED";

export type AssetCondition = "at_most" | "exactly" | "at_least";

export type TextAppliedTo = "words" | "characters";

export type Direction = "ASC" | "DESC";

export type NullHandling = "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from "axios";
import * as Axios from "axios";

declare module "axios" {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

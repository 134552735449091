/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FormApi } from "final-form";
import React, { Component, Fragment } from "react";
import { Field } from "react-final-form";
import { WithTranslation, withTranslation } from "react-i18next";
import { IProjectFormData } from "store/project/project.interface";
import { IAutocompleteField } from "types/shared";
import CustomAutocomplete from "../Input/CustomAutocomplete";
import DraggableSelect from "../Input/DraggableSelect/DraggableSelect";
import {
  CustomLanguageMappingContainer,
  CustomLanguageMappingLegend,
} from "./LanguageMapping/LanguageMappingContainer.styled";
import CustomCheckbox from "../Input/CustomCheckbox";
import { OnChange } from "react-final-form-listeners";

interface IProps {
  users: IAutocompleteField[];
  workflowSteps?: IAutocompleteField[];
  webhooks?: IAutocompleteField[];
  contentTypes?: IAutocompleteField[];
  contentElements?: IAutocompleteField[];
  kenticoSpinner?: boolean;
  fetchSpinner?: boolean;
  form: FormApi<IProjectFormData, Partial<IProjectFormData>>;
}

type PropType = IProps & WithTranslation;

export class ConnectedProjectFields extends Component<PropType> {
  usersForWebhooksRef?: HTMLInputElement;

  render(): JSX.Element {
    const {
      users,
      webhooks,
      workflowSteps,
      contentTypes,
      contentElements,
      kenticoSpinner,
      fetchSpinner,
      t,
      form: { change, getFieldState },
    } = this.props;
    const rememberContext = getFieldState("rememberContext");
    const propagateRepeat = getFieldState("propagateRepeat");
    const isRichTextSelected = getFieldState("contentElements")
      ?.value?.map((i: { value: string }) => i.value)
      .includes("rich_text");

    return (
      <Fragment>
        <Field
          name="contentTypes"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.contentTypes"
          options={contentTypes}
        />
        <Field
          name="contentElements"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.contentElements"
          options={contentElements}
        />
        <Field
          name="workflowSteps"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.workflowSteps"
          options={workflowSteps}
        />
        <Field
          name="workflowStep"
          component={CustomAutocomplete}
          spinner={kenticoSpinner}
          label="projects.workflowStep"
          options={workflowSteps}
        />

        {isRichTextSelected && (
          <Fragment>
            <Field
              name="rememberContext"
              type="checkbox"
              label="projects.rememberContext"
              tooltipText="projects.tooltips.rememberContext"
              component={CustomCheckbox}
              initialValue={rememberContext?.value}
            />
            <Field
              name="propagateRepeat"
              type="checkbox"
              label="projects.propagateRepeat"
              tooltipText="projects.tooltips.propagateRepeat"
              checkboxTooltipText="projects.tooltips.propagateRepeatCheckbox"
              component={CustomCheckbox}
              initialValue={propagateRepeat?.value}
              disabled={!rememberContext?.value}
            />
          </Fragment>
        )}

        <OnChange name={"rememberContext"}>
          {(value) => {
            if (rememberContext?.visited && !value) {
              change("propagateRepeat", false);
            }
          }}
        </OnChange>

        <CustomLanguageMappingContainer>
          <CustomLanguageMappingLegend>
            {t("projects.webhooks")}
          </CustomLanguageMappingLegend>
          <Field
            name="webhooks"
            component={DraggableSelect}
            spinner={kenticoSpinner}
            label="projects.webhooks"
            options={webhooks}
          />
          <Field
            name="usersForWebhooks"
            component={DraggableSelect}
            label="projects.usersForWebhooks"
            spinner={fetchSpinner}
            options={users}
            allowDragAndDrop
          />
        </CustomLanguageMappingContainer>
        <Field
          name="usersAssigned"
          component={DraggableSelect}
          label="projects.usersAssigned"
          spinner={fetchSpinner}
          options={users}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(ConnectedProjectFields);

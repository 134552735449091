import { Box } from '@material-ui/core';
import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import ProjectConnectAccordion from 'components/Form/Accordion/ProjectConnectAccordion/ProjectConnectAccordion';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import ConnectedProjectFields from 'components/Form/ConnectedFields/ConnectedProjectFields';
import LanguageMappingContainer from 'components/Form/ConnectedFields/LanguageMapping/LanguageMappingContainer';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomAutocomplete from 'components/Form/Input/CustomAutocomplete';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomField from 'components/Form/Input/CustomField';
import FormTitle from 'components/Form/Title/FormTitle';
import { createForm, FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { AppDispatch, AppState } from 'store';
import { getAllClients } from 'store/client/client.actions';
import { ClientActionTypes } from 'store/client/client.actions.types';
import { getAllActiveClientsSelector } from 'store/client/client.selectors';
import {
  addProject,
  clearProjectErrors,
  editProject,
  getContentElements,
  getContentTypes,
  getKenticoLanguages,
  getProjectById,
  getWebhooks,
  getWorkflowSteps,
  getXtmLanguages,
  clearProjectFormAll,
  updateFormProject,
} from 'store/project/project.actions';
import { ProjectActionTypes } from 'store/project/project.actions.types';
import { ILanguageMap, IProjectForm, IProjectFormData } from 'store/project/project.interface';
import { getProjectErrorsSelector, getProjectFormSelector } from 'store/project/project.selectors';
import {
  getFetchSpinnerSelector,
  getKenticoDataSpinnerSelector,
  getProjectSpinnerSelector
} from 'store/spinner/spinner.selectors';
import { getUsersByClientId } from 'store/user/user.actions';
import { UserActionTypes } from 'store/user/user.actions.types';
import { IAutocompleteField, ILanguageMappingField } from 'types/shared';
import {
  composeValidators,
  fetchValidator,
  required,
  requiredAutocomplete,
  requiredAutocompleteBasedOnField
} from 'utils/customValidators';
import {
  ClientDTO,
  ContentElementType,
  CreateOrUpdateProjectDTO,
  KontentProjectDTO,
  UserOverviewDTO,
} from 'utils/restApplicationClient';

interface IState {
  submitValues?: CreateOrUpdateProjectDTO;
  users: UserOverviewDTO[];
  kontentProjectId?: string;
}

interface IDispatchProps {
  getAllClients: () => AppDispatch;
  getKenticoLanguages: (payload: string) => AppDispatch;
  getXtmLanguages: () => AppDispatch;
  getUsersByClientId: (payload: string) => AppDispatch;
  getWebhooks: () => AppDispatch;
  clearProjectErrors: () => AppDispatch;
  getWorkflowSteps: () => AppDispatch;
  getContentTypes: () => AppDispatch;
  getContentElements: () => AppDispatch;
  addProject: (payload: CreateOrUpdateProjectDTO) => AppDispatch;
  editProject: (projectId: string, projectData: CreateOrUpdateProjectDTO) => AppDispatch;
  getProjectById: (payload: string) => AppDispatch;
  updateFormProject: (payload: KontentProjectDTO) => AppDispatch;
  clearProjectFormAll: () => AppDispatch;
}

interface IStateProps {
  clients: ClientDTO[];
  projectSpinner: boolean;
  kenticoSpinner: boolean;
  fetchSpinner: boolean;
  errors: { [key: string]: string | undefined };
  clientForm: IProjectForm;
}

interface IProps {
  native?: boolean;
}

interface IMatchParams {
  id?: string;
}

type IPropType = RouteComponentProps<IMatchParams> & IProps & IStateProps & IDispatchProps & WithTranslation;

class ProjectFormContainer extends Component<IPropType, IState> {
  private unsubscribe: (() => void)[] = [];

  constructor(props: IPropType) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount(): void {
    const {
      getAllClients,
      getWebhooks,
      getContentElements,
      getContentTypes,
      getWorkflowSteps,
      getKenticoLanguages,
      getUsersByClientId,
      clearProjectErrors,
      getProjectById,
      getXtmLanguages,
      match: {
        params: { id: projectId },
      },
    } = this.props;
    const clientsSubscription = subscribeActionAfter(ClientActionTypes.GetAllClientsSuccess, () => {
      if (projectId) {
        getProjectById(projectId);
      }
    });
    const usersSubscription = subscribeActionAfter(UserActionTypes.getUsersByClientIdSuccess, (action) =>
      this.setState({
        users: (action as PayloadAction<UserOverviewDTO[]>).payload,
      }),
    );

    const kenticoConnectSubscription = subscribeActionAfter(ProjectActionTypes.ConnectToKenticoSuccess, (action) => {
      const clientId = this.form.getFieldState('assignedClient')?.value?.value || this.props?.clientForm.project?.clientUUID;

      if (clientId) {
        getUsersByClientId(clientId);
      }
      updateFormProject((action as PayloadAction<KontentProjectDTO>).payload);
      getWebhooks();
      getWorkflowSteps();
      getContentElements();
      getContentTypes();
      getXtmLanguages();
      getKenticoLanguages((action as PayloadAction<string>).payload);
    });

    getAllClients();
    clearProjectErrors();

    this.unsubscribe.push(
      clientsSubscription,
      usersSubscription,
      kenticoConnectSubscription,
    );
  }

  componentDidUpdate(_: IPropType, previousState: IState): void {
    const { project, kontentProjectId } = this.props.clientForm;
    const shouldClearDetails = project?.kontentProjectId !== kontentProjectId && project?.kontentProjectId !== undefined;

    if (shouldClearDetails && project && kontentProjectId) {
      this.props.updateFormProject({...project, kontentProjectId, contentTypeIds: [], contentElementTypes: [], handledWorkflowIds: [], translatedContentWorkflowStepId: '', webhookIds: [], usersForWebhooks: [], assignedUserIds: [], languageMapping: [] })
    }

    if(previousState.kontentProjectId !== kontentProjectId){
      project && this.form.initialize(this.parseFormData(project, shouldClearDetails));
    }
  }

  componentWillUnmount(): void {
    this.props.clearProjectErrors();
    this.props.clearProjectFormAll();
    this.unsubscribe.forEach((unsubscribe) => unsubscribe());
  }

  parseLanguageMappingFieldToForm(data: ILanguageMap[], translate?: boolean): ILanguageMappingField[] {
    const { t } = this.props;

    return data.map(({ xtmLanguage, kenticoLanguage }) => ({
      xtmLanguage: { value: xtmLanguage, label: translate ? t(`projects.fieldValue.${xtmLanguage}`) : xtmLanguage },
      kenticoLanguage: {
        value: kenticoLanguage,
        label: translate ? t(`projects.fieldValue.${kenticoLanguage}`) : kenticoLanguage,
      },
    }));
  }

  parseLanguageMappingFieldToSubmit(data: ILanguageMappingField[]): ILanguageMap[] {
    return data.map(({ xtmLanguage, kenticoLanguage }) => ({
      xtmLanguage: xtmLanguage.value,
      kenticoLanguage: kenticoLanguage.value,
    }));
  }

  parseAutocompleteFieldToValue(field: IAutocompleteField[]): string[] {
    return field.map((data) => data.value);
  }

  parseFormData(project: KontentProjectDTO, clearDetails?: boolean): Partial<IProjectFormData> {
    const { users } = this.state;
    const { clients, clientForm: {contentTypes, contentElements, webhooks, workflowSteps}} = this.props
    const foundClient = clients?.find(({ id }) => id === project?.clientUUID);
    const workflowStep = workflowSteps?.find(({ id }) => id === project?.translatedContentWorkflowStepId);

    return {
      assignedClient: { label: foundClient?.clientName ?? 'unknown', value: foundClient?.id ?? 'unknown' },
      projectName: project?.name,
      active: project?.status,
      kontentProjectId: project?.kontentProjectId ?? '',
      ...(clearDetails ? {} : {contentElements:
        contentElements
          ?.filter(({ codeName }) => project?.contentElementTypes?.find((type) => type === codeName))
          .map(({ name, codeName }) => ({ label: name, value: codeName })) || [],
      contentTypes:
        contentTypes
          ?.filter(({ id }) => project?.contentTypeIds?.find((contentId) => contentId === id))
          .map(({ id, name }) => ({ label: name, value: id })) || [],
      workflowStep: workflowStep ? { label: workflowStep.name, value: workflowStep.id } : undefined,
      usersAssigned:
        users
          ?.filter(({ id }) => project?.assignedUserIds?.find((userId) => userId === id))
          .map(({ id, email }) => ({ label: email, value: id })) || [],
      workflowSteps:
        workflowSteps
          ?.filter(({ id }) => project?.handledWorkflowIds?.find((stepId) => stepId === id))
          .map(({ id, name }) => ({ label: name, value: id })) || [],
      rememberContext: project.richTextContext,
      propagateRepeat: project.propagateRepeatTranslations,
      webhooks:
        webhooks
          ?.filter(({ id }) => project?.webhookIds?.find((webhookId) => webhookId === id))
          .map(({ id, name }) => ({ label: name, value: id })) || [],
      usersForWebhooks: users
        ?.filter(({ id }) => project?.usersForWebhooks?.find(({ userUUID }) => userUUID === id))
        .map(({ id, email }) => ({ label: email, value: id })),
      languageMappingFields:
        project?.languageMapping?.map(
          ({ kontentLanguageName, kontentLanguageCode, xtmLanguageCode, xtmLanguageName }) => ({
            kenticoLanguage: { label: kontentLanguageName, value: kontentLanguageCode },
            xtmLanguage: { label: xtmLanguageCode, value: xtmLanguageName },
          }),
        ) || []})
    };
  }

  parseSubmitFormData(project: IProjectFormData): CreateOrUpdateProjectDTO {
    return {
      clientUUID: project?.assignedClient?.value,
      projectName: project?.projectName,
      status: project?.active,
      contentElementTypes: this.parseAutocompleteFieldToValue(project?.contentElements || []) as ContentElementType[],
      kontentProjectId: project?.kontentProjectId,
      contentTypeIds: this.parseAutocompleteFieldToValue(project?.contentTypes || []),
      translatedContentWorkflowStepId: project?.workflowStep?.value as string,
      assignedUsersUUID: this.parseAutocompleteFieldToValue(project?.usersAssigned || []),
      handledWorkflowStepIds: this.parseAutocompleteFieldToValue(project?.workflowSteps || []),
      richTextContext: project.rememberContext,
      propagateRepeatTranslations: project.propagateRepeat,
      webhookIds: this.parseAutocompleteFieldToValue(project?.webhooks || []),
      usersForWebhooks:
        project.usersForWebhooks?.map(({ value }, index) => ({ userUUID: value, orderNumber: index })) || [],
      languageMapping:
        project?.languageMappingFields?.map(({ kenticoLanguage, xtmLanguage }) => ({
          xtmLanguageCode: xtmLanguage.label,
          xtmLanguageName: xtmLanguage.value,
          kontentLanguageCode: kenticoLanguage.value,
          kontentLanguageName: kenticoLanguage.label,
        })) || []
    };
  }

  onSubmit = (values: IProjectFormData): void => {
    const {
      addProject,
      editProject,
      clearProjectErrors,
      match: {
        params: { id: projectId },
      },
    } = this.props;
    const { project } = this.props.clientForm;
    const parsedData = this.parseSubmitFormData(values);

    clearProjectErrors();
    this.setState({ submitValues: parsedData });

    if (project && projectId) {
      editProject(projectId, parsedData);
    } else {
      addProject(parsedData);
    }
  };

  clientChanged = (event: React.ChangeEvent<{}>, value: IAutocompleteField | IAutocompleteField[] | null): void => {
    const { getUsersByClientId } = this.props;
    const { project, kontentProjectId } = this.props.clientForm;
    if (value && kontentProjectId) {
      getUsersByClientId((value as IAutocompleteField)?.value);
    } else {
      this.setState({ users: [] });

      if (project) {
        this.props.updateFormProject({...project, assignedUserIds: []})
      }
    }
    this.form.change('usersAssigned', undefined);
    this.form.change('usersForWebhooks', undefined);
  };

  setKontentProjectId = (kontentProjectId: string): void => {
    const { project } = this.props.clientForm
    if (project && kontentProjectId) {
      this.props.updateFormProject({...project, kontentProjectId})
    }
  };

  form: FormApi<IProjectFormData> = createForm({
    onSubmit: this.onSubmit,
    initialValues: {
      active: true,
    },
  });

  render(): JSX.Element {
    const {
      match: {
        params: { id: projectId },
      },
      clients,
      projectSpinner,
      clientForm: {
        project,
        webhooks,
        workflowSteps,
        contentTypes,
        contentElements,
        kenticoLanguages,
        xtmLanguages,
        kontentProjectId
      },
      kenticoSpinner,
      errors,
      fetchSpinner,
    } = this.props;
    const {
      users,
      submitValues,
    } = this.state;

    return (
      <FormDiv width={674}>
        <FormTitle text={projectId ? 'projects.edit' : 'projects.add'} />
        <Form
          onSubmit={this.onSubmit}
          form={this.form}
          subscription={{
            submitting: true,
            pristine: true,
            submitFailed: true,
            errors: true,
            values: true,
          }}
          validate={requiredAutocompleteBasedOnField('webhooks', ['usersForWebhooks'])}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, submitting, pristine, form, values: { active } }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Box paddingX="17px" marginBottom="115px">
                <Field
                  name="assignedClient"
                  validate={requiredAutocomplete}
                  label="projects.clientAssigned"
                  options={clients?.map(({ id, clientName }) => ({ label: clientName, value: id }))}
                  component={CustomAutocomplete}
                  onChange={this.clientChanged}
                  spinner={fetchSpinner}
                />
                <Field name="active" type="checkbox" label="common.active" component={CustomCheckbox} />
                <Field
                  name="projectName"
                  component={CustomField}
                  validate={composeValidators([
                    required,
                    fetchValidator(errors['projectName'], submitValues?.projectName),
                  ])}
                  key={errors['projectName'] ? `projectNameError${submitValues?.projectName}` : 'projectName'}
                  label="projects.projectName"
                  disableAutocomplete
                />
                <Field
                  name="kontentProjectId"
                  validate={active ? required : !!projectId ? undefined : required}
                  key={active ? 'required' : !!projectId ? 'not-required' : 'required'}
                  onConnect={this.setKontentProjectId}
                  kontentProjectId={project?.kontentProjectId}
                  isProjectIdEditable={project?.editableKontentProjectId}
                  component={ProjectConnectAccordion}
                />

                {kontentProjectId && (
                  <ConnectedProjectFields
                    users={users?.map(({ email, id }) => ({ label: email, value: id }))}
                    webhooks={webhooks?.map(({ name, id }) => ({ label: name, value: id }))}
                    workflowSteps={workflowSteps?.map(({ name, id }) => ({ label: name, value: id }))}
                    contentTypes={contentTypes?.map(({ name, id }) => ({ label: name, value: id }))}
                    contentElements={contentElements?.map(({ name, codeName }) => ({ label: name, value: codeName }))}
                    kenticoSpinner={kenticoSpinner}
                    fetchSpinner={fetchSpinner}
                    form={form}
                  />
                )}
                {project && project.createdAt && project.modifiedAt && (
                  <EditData createdAt={project.createdAt} modifiedAt={project.modifiedAt} />
                )}
                {kontentProjectId && (
                  <LanguageMappingContainer
                    kenticoLanguages={kenticoLanguages?.map(({ name, codeName }) => ({
                      label: name,
                      value: codeName,
                    }))}
                    xtmLanguages={xtmLanguages?.map(({ xtmLanguageCode, xtmLanguageName }) => ({
                      label: xtmLanguageCode,
                      value: xtmLanguageName,
                    }))}
                    form={this.form}
                  />
                )}
                <ButtonContainer
                  spinner={fetchSpinner || kenticoSpinner || projectSpinner}
                  backTo="/projects"
                  submitting={submitting || pristine}
                />
              </Box>
            </form>
          )}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getAllClients: (): AppDispatch => dispatch(getAllClients()),
  getKenticoLanguages: (payload: string): AppDispatch => dispatch(getKenticoLanguages(payload)),
  getXtmLanguages: (): AppDispatch => dispatch(getXtmLanguages()),
  getUsersByClientId: (payload: string): AppDispatch => dispatch(getUsersByClientId(payload)),
  getWebhooks: (): AppDispatch => dispatch(getWebhooks()),
  getWorkflowSteps: (): AppDispatch => dispatch(getWorkflowSteps()),
  getContentTypes: (): AppDispatch => dispatch(getContentTypes()),
  getContentElements: (): AppDispatch => dispatch(getContentElements()),
  addProject: (payload: CreateOrUpdateProjectDTO): AppDispatch => dispatch(addProject(payload)),
  editProject: (projectId: string, projectData: CreateOrUpdateProjectDTO): AppDispatch =>
    dispatch(editProject({ projectId, projectData })),
  getProjectById: (payload: string): AppDispatch => dispatch(getProjectById(payload)),
  clearProjectErrors: (): AppDispatch => dispatch(clearProjectErrors()),
  updateFormProject: (payload: KontentProjectDTO): AppDispatch => dispatch(updateFormProject(payload)),
  clearProjectFormAll: (): AppDispatch => dispatch(clearProjectFormAll()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  clients: getAllActiveClientsSelector(state),
  clientForm: getProjectFormSelector(state),
  projectSpinner: getProjectSpinnerSelector(state),
  kenticoSpinner: getKenticoDataSpinnerSelector(state),
  fetchSpinner: getFetchSpinnerSelector(state),
  errors: getProjectErrorsSelector(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectFormContainer)));

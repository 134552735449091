import React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  tooltipPlacementRight: {
    marginLeft: "8px !important",
  },
  tooltipPlacementLeft: {
    marginRight: "8px !important",
  },
  tooltipPlacementTop: {
    marginBottom: "8px !important",
  },
  tooltipPlacementBottom: {
    marginTop: "8px !important",
  },
}));

const SimpleTooltip = ({ children, ...props }: TooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip arrow classes={classes} {...props}>
      {children}
    </Tooltip>
  );
};
export default SimpleTooltip;

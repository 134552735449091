/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Typography } from "@material-ui/core";
import { FieldRenderProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  CheckboxDiv,
  CustomStyledCheckbox,
  FieldDiv,
} from "./CustomInput.styled";
import SimpleTooltip from "../../CustomTooltip/SimpleTooltip";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";

interface IProps extends FieldRenderProps<boolean> {
  disabled?: boolean;
  label: string;
  tooltipText?: string;
  checkboxTooltipText?: string;
}

const useStyles = makeStyles({
  typography: {
    display: "flex",
    width: 276,
  },
  typographyBox: {
    fontWeight: 500,
    textAlign: "start",
    width: 276,
    fontSize: 14,
    color: ({ disabled }: IProps) => (disabled ? "#8F8F8F" : "#333"),
  },
  icon: {
    color: "#00A4E4",
    marginRight: 6,
  },
});

const CustomCheckbox = (props: IProps): JSX.Element => {
  const { disabled, input, label, tooltipText, checkboxTooltipText } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <FieldDiv>
      <Typography component="div" className={classes.typography}>
        <Box className={classes.typographyBox}>{t(label)}</Box>
        {tooltipText && (
          <SimpleTooltip title={t(tooltipText) as string} placement="right">
            <InfoIcon className={classes.icon} />
          </SimpleTooltip>
        )}
      </Typography>
      <CheckboxDiv>
        <SimpleTooltip
          title={
            (disabled && checkboxTooltipText
              ? t(checkboxTooltipText)
              : "") as string
          }
          placement="bottom"
        >
          <Box marginLeft={"-12px"}>
            <CustomStyledCheckbox
              {...input}
              color={"secondary"}
              disabled={disabled}
            />
          </Box>
        </SimpleTooltip>
      </CheckboxDiv>
    </FieldDiv>
  );
};

export default CustomCheckbox;
